.button {
  padding: 1rem 2rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  font-weight: 800;
  transition: all ease 0.3s;
  display: flex;
  font-size: 20px;
  align-items: center;
}

.button.dark {
  /* background-color: #375042;
  color: #fff;
  filter: drop-shadow(#8dc63f 0px 0px 10px); */

  background-image: linear-gradient(
    132deg,
    rgba(0, 113, 187, 1) 14.58%,
    rgba(84, 163, 113, 1) 65.1%,
    rgba(141, 198, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  border: 1px solid #8dc63f;
}

.button.light {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.button.orange {
  background-color: orange;
  color: #fff;
}

.imageBlock {
  margin-right: 0.5rem;
}
