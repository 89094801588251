/* .offerBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a5d4a6;
  color: #063d4d;
  padding: 0.5rem 2rem;
  font-weight: 600;
  position: fixed;
  margin: 1rem auto;
  margin-left: 5%;
  width: 90%;
  z-index: 1000;
  filter: drop-shadow(0 0 0.75rem #063d4d);
} */

.offerBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #063d4d;
  color: #fff;
  padding: 0.5rem 2rem;
  font-weight: 600;
  position: fixed;
  min-height: 42px;
  /* margin: 1rem auto; */
  /* margin-left: 5%; */
  width: 100%;
  z-index: 29;
}

.bannerContent {
  flex-grow: 1;
  text-align: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .bannerContent {
    font-size: 12px;
  }
}
