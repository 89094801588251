/* blogsPage.css */
.blog-container {
  background: #fff;
  margin: auto;
  padding: 4rem 6rem;
  margin: 3rem 0 0;
}

@media screen and (max-width: 1080px) {
  .blog-container {
    margin: auto;
    padding: 2rem 1rem;
  }
}

.blog-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.blog-main {
  flex: 3; /* Takes up the majority of the space */
  padding-right: 20px; /* Space between the main content and the index */
}

.blog-index {
  flex: 1; /* Smaller space for the index */
  position: sticky;
  top: 100px; /* Adjust based on your header/nav height */
  padding: 10px;
  height: max-content;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  max-width: 100%;
  padding: 1rem;
  font-size: 16px;
  font-weight: 800;
}

.blog-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  background-color: #fff !important;
}

@media (max-width: 992px) {
  .blog-content-wrapper {
    flex-direction: column;
  }

  .blog-index {
    position: relative;
    top: 0;
    width: 100%; /* Full width on smaller screens */
    order: -1; /* Index comes first on small screens */
  }
}

/* .blogDetails {
  background: #ecf3f0;
} */

.contentPoints {
  color: #063d4d;
}
