.faqSectionHeading {
  color: #063d4d;
  font-weight: 800;
  font-size: 2.441rem;
}
@media screen and (max-width: 768px) {
  .faqSectionHeading {
    font-size: 1.5rem;
  }
}
