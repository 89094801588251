.bannerSection {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fff;
  align-items: center;
  padding: 12rem 0 0 0;
}

@media screen and (max-width: 600px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 105);
  }
}

@media screen and (max-width: 380px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 145);
  }
}

@media screen and (max-width: 370px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 100);
  }
}

.bannerContentSection {
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .bannerContentSection {
    max-width: 100%;
  }
}

.bannerAnimationDesktop {
  display: flex;
  z-index: 1;
  justify-content: center;
}

.bannerAnimationMobile {
  display: none;
}

@media screen and (max-width: 760px) {
  .bannerAnimationDesktop {
    display: none;
  }
  .bannerAnimationMobile {
    display: flex;
    position: absolute;
    bottom: 0;
  }
  .bannerAnimationMobile svg {
    width: 100vw !important;
  }
}

.bannerAnimationDesktop svg {
  /* height: calc(var(--vh, 1vh) * 120) !important; */
  z-index: -1;
  width: 88% !important;
  height: 90% !important;
}

.caption {
  text-align: center;
}

.mainBannerHeading {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
}

.gradient-text {
  /* background-size: 250% 250%; */
  background-image: linear-gradient(
    132deg,
    rgba(141, 198, 63, 1) 14.58%,
    rgba(84, 163, 113, 1) 65.1%,
    rgba(0, 113, 187, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  /* animation: gradient-animation 10s ease infinite; */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.subHeading {
  font-size: 20px;
  color: #416052;
  width: 100%;
  max-width: 50vw;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}

.bannerButton {
  width: 100%;
  display: flex;
  margin: 2rem auto 0;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 360px) {
  .mainBannerHeading {
    font-size: 1.75rem;
    margin-top: 0rem;
  }
}
@media screen and (max-width: 768px) {
  .mainBannerHeading {
    font-size: 1.75rem;
    margin-top: -15rem;
  }
  .subHeading {
    width: 100%;
    font-size: 16px;
    max-width: 100vw;
  }
  .bannerButton {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.goToProfileButton {
  padding: 1rem;
  background: #063d4d;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 20px;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-weight: 800;
}
