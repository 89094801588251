.curatedNews {
  /* height: 100svh; */
  padding: 4rem 0 0;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .curatedNews {
    padding: 2rem 0 0;
    /* height: 55vh; */
  }
}
.newsDetailBox {
  padding: 1rem;
  display: flex;
  background: #fff;
  border-radius: 10px;
  height: 100%;
}

.newsBox {
  max-height: 500px;
  overflow-y: auto;
}

.newsItem {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 1rem;
}

.thumbnail {
  min-width: 100%;
  max-height: 175px;
  margin-bottom: 10px;
}

.newsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 185px;
}

.newsContent .newsTitle {
  font-size: 1rem;
  font-weight: 800;
  color: #063d4d;
  text-decoration: none;
  margin-bottom: 10px;
  min-height: 76px;
}

.newsDescription {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  min-height: 84px;
  max-height: 84px;
}
.date {
  font-size: 0.8rem;
  color: #a4a4a4;
  text-align: right;
  width: 100%;
  font-weight: 600;
}

/* react-slick specific styles */
.slick-prev,
.slick-next {
  display: none !important;
}

/* .slick-prev:hover,
.slick-next:hover {
  background: #354b42;
} */

/* .slick-dots li button:before {
  color: #416052;
} */

.slick-slide {
  /* box-shadow: -4px 9px 25px -6px rgba(0, 0, 0, 0.1); */
  /* filter: drop-shadow(#8dc63f 0px 0px 4px); */
  margin: 0.5rem;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  /* max-height: 450px; */
  background: #fff;
  /* min-height: 450px; */
  cursor: pointer;
  overflow: hidden;
}
