.taxFilingContainer {
  margin: 4rem auto;
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.taxFilingForm {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.taxStep {
  margin-bottom: 2rem;
}

.taxStepTitle {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
}

.taxFormGroup {
  margin-bottom: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.taxFormLabel {
  font-weight: 600;
  color: #063d4d;
  font-size: 14px;
  max-width: 15%;
}

.taxFileUploadbutton {
  width: 25%;
}
@media screen and (max-width: 1080px) {
  .taxFormGroup {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
  .taxFormLabel {
    max-width: 100%;
  }
  .taxFileUploadbutton {
    width: 100%;
  }
}

.taxSection {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
}

.taxSectionTitle {
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #063d4d;
  font-weight: 600;
}

.taxStepButtons {
  display: flex;
  justify-content: space-between;
}

.taxFinishButton {
  color: #fff;
  border: none;
}

.taxConfirmationMessage {
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  background-color: #e0f7fa;
  border-radius: 8px;
}

.taxFileInput {
  display: none;
}

.taxFileUploadButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #8dc63f;
  color: #fff;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.taxFileUploadButton label {
  cursor: pointer;
}

.googleButtonCalendar {
  padding: 0.5rem 0;
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  cursor: pointer;
  color: #000;
  font-weight: 800;
  font-size: 20px;
  width: 25%;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 0 auto;
  border: 1px solid black;
  /* background: #1a73e8; */
}

.form-check-label {
  font-size: 16px;
  font-weight: 600;
  color: #063d4d;
}

.register-link {
  font-weight: 800;
  color: #063d4d;
  cursor: pointer;
}

/* tax services  css starts */
/* tax services  css starts */
/* tax services  css starts */
/* tax services  css starts */

.taxServices {
  padding: 4rem 0rem;
  background: #fff;
  margin: 3rem 0 0;
}

.heading {
  color: #063d4d;
  text-align: center;
}

.subheading {
  color: #063d4d;
  text-align: center;
}

.paragraph {
  line-height: 1.3;
  color: #063d4d;
}

.services {
  margin-top: 20px;
}

.sectionHeading {
  color: #063d4d;
  margin-top: 20px;
  font-weight: 600;
}

.subsectionHeading {
  color: #063d4d;
  margin-top: 10px;
}

.serviceSection {
  margin-bottom: 20px;
}

.list {
  list-style-type: disc;
  padding-left: 20px;
}

.listItem {
  margin-bottom: 5px;
  max-width: 80%;
}
@media screen and (max-width: 768px) {
  .listItem {
    max-width: 100%;
  }
}

.getTaxServices {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.orderedList {
  padding-left: 20px;
}

.orderedListItem {
  margin-bottom: 5px;
}

.orderedListItem strong {
  color: #063d4d;
}

.loginButton {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #063d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 767px) {
  .getTaxServices {
    position: static;
    width: 90%;
    margin: 1rem auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
