.cardContainer {
  margin-bottom: 1.25rem;
  border-radius: 16px;
  transition: box-shadow 0.3s ease-in-out;
}

.cardContainer:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.amInvestCard {
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  overflow: hidden;
}

.amInvestCardImgTop {
  max-width: 125px;
  max-height: 125px;
  min-height: 125px;
  width: 100%;
  margin: 0 auto;
}

.amInvestCardBody {
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.amInvestCardTitle {
  font-weight: 800;
  color: #000;
  font-size: 20px;
}
.amInvestCardTitle,
.amInvestCardText,
.amInvestCardMetadata {
  margin-bottom: 10px;
}

.amInvestCardBodyBlog {
  padding: 15px;
  display: flex;
  flex-direction: column;
  color: #063d4d;
  text-align: left;
  justify-content: space-between;
  bottom: 0;
  min-height: 220px;
}

.amInvestCardTitleBlog {
  font-size: 20px;
  font-weight: 800;
  min-height: 48px;
}

.amInvestCardTextBlog {
  font-size: 14px;
  font-weight: 700;
  min-height: 84px;
}

.fundsCard {
  z-index: 10;
  position: relative;
  padding: 1rem;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  max-height: 40vh;
  flex-direction: column;
}

/* .hiddenCard {
  display: flex;
  height: 7vh;
  width: 100%;
  border-radius: 16px;
  background: #375042;
  margin-top: -25%;
} */

.blogCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 9px 30px rgba(62, 87, 3, 0.1);
}

.fundsCardDescription {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #063d4d;
}

.cardImgTopBlog {
  width: 100%;
  max-height: 175px;
}
