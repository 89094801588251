/* src/components/AmInvestCalculatorList.css */

.calculatorListContainer {
  margin-top: 8rem;
  padding: 2rem 1rem;
  border-radius: 8px;
}

.calculatorListTitle {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}

.calculatorCard {
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(181, 172, 172, 0.1);
  transition: all 0.3s ease-in-out;
}

.calculatorCard:hover {
  transform: translateY(-5px);
  filter: drop-shadow(0 0 0.15rem #8dc63f);
}

.calculatorCardTitle {
  font-size: 1.25rem;
  color: #333;
  font-weight: bold;
}

.calculatorCardDescription {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  min-height: 65px;
}

.calculatorCardImage {
  width: 20%;
  height: auto;
  float: right;
  border-radius: 8px;
}
