.stockBundles {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 4rem 4rem;
  background: #fff;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  display: flex;
}
.ourServices {
  display: flex;
  padding: 2rem 4rem;
  min-height: 24rem;
  min-width: 1200px;
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  box-shadow: 0 2px 10px #665dbe4d;
}

.animationCoinDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
}
.animationCoinDrop svg {
  width: 75% !important;
}

.services-name {
  grid-column-gap: 12px;
  min-width: fit-content;
  box-shadow: none;
  color: #063d4d;
  background-color: #efedfa;
  border-radius: 12px;
  font-weight: 800;
  height: 40px;
  padding: 0.5rem 1rem;
}

.fundsCardRow {
  display: flex;
  gap: 0.5rem;
}

.view-more-services {
  font-weight: 800;
  color: #0071bb;
  font-size: 20px;
  margin: 1rem 0 0 0;
  padding: 0;
  cursor: pointer;
}
.spanStylesOne {
  padding: 1rem;
  margin: 1rem 0;
  /* background-image: linear-gradient(
    132deg,
    rgba(0, 113, 187, 1) 14.58%,
    rgba(84, 163, 113, 1) 65.1%,
    rgba(141, 198, 63, 1) 100%
  ); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; */
  /* color: transparent; */
  color: #fff;
  background: #063d4d;
  border: 1px solid #8dc63f;
  border-radius: 8px;
}
.spanStylesTwo {
  padding: 1rem;
  margin: 1rem 0;
  background-image: linear-gradient(
    132deg,
    rgba(0, 113, 187, 1) 14.58%,
    rgba(84, 163, 113, 1) 65.1%,
    rgba(141, 198, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  border: 1px solid #8dc63f;
  border-radius: 8px;
  /* color: transparent;
  color: #fff;
  background: #063d4d;
  border: 1px solid #8dc63f;
  /* border-radius: 8px; */
}

@media screen and (max-width: 1080px) {
  .fundsCardRow {
    gap: 2rem;
  }
  .stockBundles {
    padding: 2rem 0.25rem;
  }
}
