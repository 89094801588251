.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  background-color: transparent;
  transition: background-color 0.3s, transform 0.3s;
  top: 42px;
  transition: top 0.8s;
}

@media screen and (max-width: 768px) {
  .navbar {
    top: 50px;
  }
}
.navbar.without-banner {
  top: 0;
}
.navbar.visible {
  background-color: white;
}

.navbar.bottomShadow {
  filter: drop-shadow(0 0 0.1rem #ecf3f0);
}

.navbar.hidden {
  transform: translateY(-200%);
}

.logo,
.logo-menu {
  max-width: 200px;
}

.hamburger {
  display: block;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  position: relative;
  z-index: 35;
}

.hamburger span {
  display: block;
  width: 30px;
  height: 3px;
  margin: 6px 0;
  background-color: #063d4d;
  transition: transform 0.4s, background-color 0.4s;
}

.hamburger.is-open span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.is-open span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-open span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.menu {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  align-items: flex-start;
  padding: 0 1rem;
  font-weight: 600;
  z-index: 30;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.menu.open {
  display: flex;
  opacity: 1;
  gap: 0.75rem;
  padding: 1.5rem 1rem;
  transform: translateY(0);
}

.menu a {
  color: #063d4d;
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
}

.getStarted {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  transition: all 0.3s ease;
  border-radius: 8px;
  background: transparent;
  color: #063d4d;
  border: 1px solid #8dc63f;
}

@media (min-width: 768px) {
  .hamburger,
  .logo-menu {
    display: none;
  }

  .menu {
    padding: 0;
    display: flex;
    position: static;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    transform: none;
    opacity: 1;
    background: none;
    width: auto;
    height: auto;
  }
}
