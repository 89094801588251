.investEquity {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  position: relative;
}
.equity-data {
  position: relative;
  max-width: 1200px;
  padding: 4rem 3rem;
  border-radius: 2rem;
  overflow: hidden;
}

.equity-data::before {
  content: "";
  position: absolute;
  inset: 0;
  /* border-radius: 2rem; */
  padding: 0 0 4px;
  background: linear-gradient(45deg, #8dc63f, #00b4d8, #8dc63f, #00b4d8);
  background-size: 400% 400%;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.secondary-heading {
  font-size: 45px;
  font-weight: 600;
}

.secondary-content {
  font-weight: 500;
}
