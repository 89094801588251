.visionStatement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4rem;
  background: hsla(145, 84%, 73%, 1);
  background: linear-gradient(
    90deg,
    hsla(145, 84%, 73%, 1) 0%,
    hsla(150, 61%, 48%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(145, 84%, 73%, 1) 0%,
    hsla(150, 61%, 48%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(145, 84%, 73%, 1) 0%,
    hsla(150, 61%, 48%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#82f4b1", endColorstr="#30c67c", GradientType=1 );
}

@media screen and (max-width: 1080px) {
  .visionStatement {
    padding: 2rem 1rem;
    margin: 19rem 0 0;
  }
}

.visionStatement h2 {
  color: #063d4d;
  width: 100%;
  font-weight: 800;
  font-size: 2.5rem;
}

.visionStatementRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  .visionStatementRow {
    padding: 0rem;
    flex-direction: column;
    align-items: baseline;
  }
}

.visionStatementContent {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0;
  color: #063d4d;
}
