/* Header Start */

.stock-list {
  border: 3px #e4e4e4 solid;
  background: #fff;
  padding: 24px 35px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.stock-list:hover {
  border-color: #063d4d;
  box-shadow: 0px 0px 20px rgba(56, 97, 81, 0.4);
}

.stock-list:hover span {
  color: #063d4d;
}

.stock-list span {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}

/*Earning Calculator */
.earning-calculator {
  padding: 120px 0 90px 0;
}

/**
 * Tabs Container
 */
.tabs-container {
  margin: 0 5rem;
  background-color: #ecf3f0;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  min-height: 24rem;
  padding: 4rem;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 10px 22px rgba(102, 93, 190, 0.3);
}

/**
 * Tabs Block
 */
/* .tabs-block {} */

/**
 * Tabs
 */
.tabs {
  display: flex;
}

.tab-head {
  list-style-type: none;
  margin: 5% 0;
  width: 100%;
  overflow-y: scroll;
  max-height: 60vh;
}

/* Handle */
.v-scroll::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  border-radius: 8px;
  width: 25px;
}

.v-scroll::-webkit-scrollbar {
  width: 20px;
}

.v-scroll::-webkit-scrollbar-track {
  background: #e7e7e7;
  border-left: 9px solid #ecf3f0;
  border-right: 9px solid #ecf3f0;
}

.v-scroll::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  border-left: 5px solid #ecf3f0;
  border-right: 5px solid #ecf3f0;
}

.tab-head li {
  display: block;
}

.tab-head li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  border: 2px #063d4d solid;
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.tab-head li a span.title {
  font-weight: 300 !important;
  font-size: 22px;
  color: #063d4d;
}

.tab-head li a span {
  font-size: 16px;
  color: #505050;
}

.tab-head li a img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.tab-head li a span.stra-count {
  font-weight: 700;
  font-size: 19px;
}

.tab-head li a .orange {
  color: #ec8f2b;
}

.tab-head li a .grey {
  color: #494949;
}

.tab-head li a .green {
  color: #17b45a;
}

.tab-head li a.active,
.tab-head li a:hover {
  border-color: #063d4d;
  box-shadow: 0px 0px 25px rgba(56, 97, 81, 0.4);
}

.tab-head li a.active span.title,
.tab-head li a:hover span.title,
.tab-head li a.active span,
.tab-head li a:hover span {
  color: #063d4d;
}

.tabs section {
  width: 100%;
  background-color: #fff;
  display: none;
  word-wrap: break-word;
}

.tabs section > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s;
}

.tabs section.active {
  display: block;
  background: #ecf3f0;
  border-radius: 16px;
}

.tabs section.active-content > * {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 576px) {
  .tabs > ul {
    width: 150px;
  }

  .tabs > ul li a {
    flex-wrap: wrap;
  }

  .tabs > ul li a > span {
    width: 100%;
  }

  .tabs > ul li a > span.tab-label {
    width: 100%;
    display: block;
    margin-top: 0.2rem;
  }

  .tabs section {
    padding: 2rem 2rem;
  }
}

.calculator-data {
  margin: 35px 0 60px 0;
  background: #ddebe5;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.calculator-data span {
  padding: 1rem 0.5rem;
  font-size: 1.75rem;
  line-height: 130%;
  font-weight: 700;
  color: #497664;
  text-transform: uppercase;
  width: 20%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 768px) {
  .calculator-data span {
    font-size: 1rem;
    line-height: 130%;
  }
}

.calculator-data span:first-child {
  border-radius: 30px 0 0 30px;
}

.calculator-data span:last-child {
  border-radius: 0 30px 30px 0;
}

.calculator-data span.active,
.calculator-data span:hover {
  color: #fff;
  background: #497664;
}

.value-box {
  font-size: 29px;
  color: #000;
}

.value-box span.value {
  font-size: 42px;
  color: #000;
  font-weight: 700;
  margin-right: 30px;
}

.value-box span.drop-value {
  color: #db0918;
}

/*Range Slide*/

.slider-container {
  margin: 0 auto 0 auto;
  display: inline-block;
  width: 100%;
}

.slider-container.unbiased > .inner {
  padding: 0;
  width: 100%;
  display: inline-block;
  margin-top: 0;
}

.slider-container.unbiased > .slider-val {
  display: inline-block;
  width: auto;
  float: left;
  text-align: center;
}

.slider-container.unbiased > .slider-val:last-of-type {
  float: right;
}

.slider-container.unbiased > .slider-val > h5 {
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 23px;
}

.slider-container.unbiased .irs {
  height: 8px;
}

.slider-container.unbiased .irs-from,
.irs-single,
.slider-container.unbiased .irs-to {
  display: none !important;
}

.slider-container.unbiased .irs-with-grid {
  height: 40px;
}

.slider-container.unbiased .irs-with-grid .irs {
  top: 8px;
}

.slider-container.unbiased .irs-line {
  height: 8px;
  bottom: 0;
  border: 0.085vw solid #639581;
  border-radius: 5px;
  background: #639581;
}

.slider-container.unbiased .irs-line-left {
  height: 0.25vw;
}

.slider-container.unbiased .irs-line-mid {
  height: 0.25vw;
}

.slider-container.unbiased .irs-line-right {
  height: 0.25vw;
}

.slider-container.unbiased .irs-bar {
  height: 0.25vw;
  bottom: 0;
  background: transparent;
}

.slider-container.unbiased .irs-bar-edge {
  bottom: 0;
  height: 0.25vw;
  width: 0.25vw;
  top: auto;
  background: transparent;
  border-radius: 0.25vw 0 0 0.25vw;
}

.slider-container.unbiased .irs-slider {
  height: 24px;
  width: 70px;
  top: 0;
  left: 50px;
  cursor: pointer;
  background: #497664;
  border-radius: 10px;
}

.irs-with-grid .irs-grid {
  display: none !important;
}

.irs-grid {
  bottom: 0.25vw;
  height: 0.85vw;
}

.irs-grid-pol {
  height: 0.85vw;
  width: 0.15vw;
  background: #757474;
}

.irs-grid-pol.small {
  display: none;
}

.irs-grid-text {
  display: none;
}

footer {
  /* background: url("../images/footer-bg.png") no-repeat center bottom #063d4d; */
  background-size: cover;
  background: #063d4d;
  color: #fff;
  padding: 125px 0 65px 0;
  font-size: 19px;
  line-height: 30px;
}

footer .subtitle {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  width: 100%;
  margin: 24px 0 34px 0;
}

footer .playstore-link {
  margin: 30px 0 80px 0;
  display: flex;
  justify-content: center;
}

footer .footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 250px;
}

footer .playstore-link a {
  max-width: 220px;
  display: inline-block;
  margin: 0 27px 10px 27px;
}

footer .playstore-link img {
  border: 3px #fff solid;
  border-radius: 8px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

footer .playstore-link img:hover {
  border: 3px #fff solid;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 0 50px 15px #b0d3c5;
}

footer h4 {
  font-size: 21px;
  color: #fff;
  font-weight: 700;

  margin-bottom: 40px;
}

footer .footer-links {
  padding: 0;
  margin: 0;
  list-style: none;
}

footer .footer-links li {
  padding-bottom: 15px;
}

footer .footer-links li a {
  text-decoration: none;
  color: #fff;
  font-size: 17px;
  line-height: 18px;
  border-bottom: 1px transparent solid;
}

footer .footer-links li:hover a {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.bottom-footer {
  padding-top: 40px;
}

footer .social-links {
  padding: 0 10px 0 0;
  margin: 0;
  text-align: right;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

footer .social-links li {
  border: 1px transparent solid;
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 26px;
  margin-right: 5px;
}

footer .social-links li:hover {
  border-color: #fff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

/* Modal Login CSS */
.close-modal,
.close-modal:hover {
  position: absolute;
  background: url("../images/close-icon.png") no-repeat center center #000;
  right: -10px;
  top: -10px;
  color: #fff;
  padding: 15px;
  border-radius: 13px;
  opacity: 1;
  z-index: 2;
}

.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

a {
  text-decoration: none !important;
}

.footer-link {
  color: #ffffff !important;
}

address .footer-flex p {
  color: #ffffff !important;
}

.copyright {
  color: #ffffff !important;
  font-size: 12px;
  line-height: 22px;
}

.h1_special {
  color: #063d4d;
  font-size: 3.0519em;
  font-weight: 800;
}

@media only screen and (max-width: 767px) {
  .h1_special {
    font-size: 1.953rem;
    margin-top: 40px !important;
    text-align: left;
  }
}

.h2_special {
  color: #063d4d;
  font-size: 2.5rem !important;
}

/* slider */
.slider-container {
  margin-bottom: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: rgb(56, 97, 81);
  outline: none;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(56, 97, 81);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(56, 97, 81);
  cursor: pointer;
}

.value-box {
  margin-top: 10px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  color: rgb(56, 97, 81);
}
.text-small {
  font-size: 12px !important;
}

.hover-card:hover {
  border: 1px #fff solid;
  border-radius: 1px;
  width: 100%;
  box-shadow: 0 0 50px 15px #b0d3c5;
}

.news-item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .news-item {
    flex-direction: column;
  }
}

.content {
  flex: 1;
}

.textSize {
  font-size: 1rem;
}

.angelInvestmentsListPage {
  display: flex;
  margin: 5rem auto;
  padding: 5rem 2rem;
}
