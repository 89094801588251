.calculatorSection {
  box-shadow: 0 2px 10px #665dbe4d;
  padding: 4rem 2rem;
  max-width: 1200px !important;
  margin: 4rem auto;
  border-radius: 16px;
  background: #fff;
}

@media screen and (max-width: 1080px) {
  .calculatorSection {
    box-shadow: 0 2px 10px #665dbe4d;
    padding: 2rem 1rem;
    margin: 2rem auto;
    border-radius: 16px;
  }
}

.calculatorSection h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.calculatorRow {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calculatorCol {
  padding: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 1080px) {
  .calculatorCol {
    /* padding: 1rem 0; */
    gap: 0.5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}
.calculatorButton {
  background: #063d4d;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 800;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}

.exploreButton {
  margin: 1rem auto;
  padding: 0;
}

.cta {
  position: relative;
  margin: auto;
  padding: 1rem 2rem;
  color: #000;
  transition: all 0.2s ease;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 12px;
  /* background: #8dc63f; */
  /* width: 56px; */
  width: 100%;

  height: 56px;
  transition: all 0.3s ease;
}
.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  color: #000;

  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
}

.cta:hover:before {
  width: 100%;
  color: #fff;
  background: #063d4d;
}
.cta:hover span {
  color: #fff;
}

.cta:active {
  transform: scale(0.96);
}
