@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  color: #063d4d;
  background: #f7f7f7;
  font-family: "Montserrat", sans-serif !important;
}
a {
  text-decoration: none;
}
main {
  /* padding: 20px 0; */
  background: #fff;
}

.form-control:focus,
.form-control:active,
.react-dropdown-select.form-control:focus,
.react-dropdown-select.form-control:focus-within {
  border-color: #416052 !important;
  box-shadow: 0 0 0 0.25rem rgb(65 96 82 / 46%) !important;
}
.react-dropdown-select.form-control:hover,
.react-dropdown-select.form-control:focus-within {
  border-color: #1a3428 !important;
}
.react-dropdown-select.form-control .react-dropdown-select-item-selected {
  background: #1a3428;
}
.react-dropdown-select.form-control .react-dropdown-select-item:hover,
.react-dropdown-select.form-control .react-dropdown-select-item:focus {
  background: #416052;
  color: #fff;
}
.react-dropdown-select.form-control .react-dropdown-select-item {
  padding: 12px 10px;
}
.registration-data h1 {
  font-size: 55px;
  color: #416052;

  margin-bottom: 50px;
  margin-top: -320px;
}

.container {
  max-width: 1300px !important;
}
h2 {
  font-size: 2.441rem !important;
  font-weight: 800 !important;
  letter-spacing: 0 !important;
  line-height: 130% !important;
  text-align: center;
  color: #063d4d !important;
}

@media screen and (max-width: 1080px) {
  h2 {
    font-size: 20px !important;
    font-weight: 800 !important;
    line-height: 130% !important;
    text-align: left;
  }
}

.small-text {
  font-size: 12px;
  color: #949494;
}

.btn-completed {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  background: #8dc63f;
  border-color: #8dc63f;
  box-shadow: none;
  outline: none;
  border-radius: 8px;
  padding: 5px 15px;
}
.btn-completed:hover,
.btn-completed:focus,
.btn-completed:first-child:active {
  box-shadow: none;
  outline: none;
  background: #fff;
  color: #8dc63f;
  border-color: #8dc63f;
}
.btn-red {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  background: #ba132b;
  border-color: #ba132b;
  box-shadow: none;
  outline: none;
  border-radius: 8px;
  padding: 5px 15px;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:first-child:active {
  box-shadow: none;
  outline: none;
  background: #fff;
  color: #ba132b;
  border-color: #ba132b;
}
.navbar-toggler {
  background: #fff !important;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.search-area {
  position: relative;
  margin-right: 32px;
}
.search-area::after {
  position: absolute;
  right: 16px;
  top: 10px;
  content: "";
  width: 16px;
  height: 16px;
  background: url("./components/images/search-icon.png") 0 0 no-repeat;
  background-size: 100%;
}
.navbar .form-control {
  border-radius: 5px;
  color: #c2c2c2;
  font-size: 16px;
  font-weight: 500;
  border-color: #e5e5e5;
}
hr {
  border-color: #ededed;
  opacity: 1;
  margin-bottom: 30px;
}
.form-control:disabled {
  background: #f1f1f1;
  border-color: #f1f1f1;
}

.sidebar {
  /* box-shadow: 0 0 29px rgba(0, 0, 0, 0.1); */
  border: none;
  /* margin: 0px 16px 35px 0; */
  padding: 0.5rem 0 0;
  background: #fff;
  border-radius: 8px;
  overflow: auto;
}

.sidebar .user-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  /* justify-content: flex-start; */
  /* padding: 40px 25px 16px 25px; */
}
/* .sidebar .user-block .user-img {
  width: 90px;
  height: 90px;
  object-fit: contain;
  border-radius: 10px;
  margin: 0 35px 0 0;
} */
.sidebar .user-block .user-name {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.sidebar ul {
  padding: 0;
  margin: 0;
  display: block;
}
@media screen and (max-width: 768px) {
  .sidebar ul {
    display: flex;
  }
}
.sidebar ul li {
  /* padding: 1rem; */
  margin: 0;
  display: inline-block;
  width: 100%;
  list-style: none;
}
.sidebar ul li .sidebar-link {
  color: #000;
  padding: 15px 40px;
  font-size: 18px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
}
@media screen and (max-width: 1080px) {
  .sidebar ul li .sidebar-link {
    padding: 1rem;
  }
}
.sidebar ul li.active,
.sidebar ul li:hover {
  background: #416052;
  /* border-bottom: 1px solid black; */
  color: #fff !important;
}

.sidebar ul li.active .sidebarLink {
  color: #fff !important;
}
.sidebar ul li.active .sidebar-link,
.sidebar ul li:hover .sidebar-link {
  color: #fff !important;
}
.form-data {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
}

/* .form-data .form-label{
  text-transform: uppercase;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #949494;
  letter-spacing: 1px;
} */
.form-data .react-datepicker-wrapper {
  width: 100%;
}

.react-select .css-b62m3t-container {
  border-color: #dee2e6;
}
.react-select .css-qbdosj-Input {
  padding: 10px 16px 10px 25px;
  font-size: 16px;
  line-height: 16px;
  color: #3e3e3e;
  font-weight: 500;
  margin: 0;
}
.form-data .mb-form {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.edit-input .form-control {
  background: #f0f0f0;
}
.edit-input {
  position: relative;
}
.edit-input::after {
  position: absolute;
  right: 16px;
  top: 15px;
  content: "";
  background: url("./components/images/copy-icon.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 16px;
  width: 16px;
}

.form-data small {
  font-size: 16px;
  color: #949494;
  font-weight: 500;
}

.generate-pin-data {
  margin-bottom: 50px;
  /* border-bottom: 1px #e0e0e0 solid; */
}
.status-btn {
  margin-top: 10px;
}

.registration-data {
  margin-right: 40px;
}
.registration-data span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 60px;
  color: #000;
}
.registration-data small {
  font-size: 16px;
  color: #636363;

  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
}
.registration-data .btn-green {
  width: 490px;
  max-width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 25px;
}

.psw-filed {
  position: relative;
}
.psw-filed .form-control {
  padding-right: 60px;
}
.psw-filed .psw-icon {
  position: absolute;
  right: 16px;
  bottom: 14px;
}
input[type="file"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

label.input-file-label {
  padding: 0;
  font-size: 16px;
  line-height: 26px;
  color: #3e3e3e;
  display: table;
  width: 100%;
}
/****************888 Screener Css Start ************************/
.analytics-area {
  margin: 8rem auto;
}
.analytics-area .submit img {
  margin-left: 16px;
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.analytics-search {
  width: 23%;
  margin-right: 10px;
}
.analytics-area .analytics-search-area {
  display: flex;
  align-items: center;
}
.analytics-search .form-control {
  padding: 15px 16px 15px 30px;
  font-size: 16px;
  line-height: 16px;
  color: #3e3e3e;
  font-weight: 500;
  border-radius: 0.375rem;
  min-height: 47px;
  position: relative;
}
.screener-list {
  display: flex;
  background: #fff;
  border: 1px #e6e6e6 solid;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-bottom: 15px;
}
.screener-list:hover {
  border-color: #416052;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}
.screener-list .list-icon {
  width: 75px;
  text-align: center;
  margin-right: 16px;
}
.screener-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.screener-list .screener-info p {
  font-size: 18px;
  color: #416052;
  margin-bottom: 10px;
}
.budget {
  color: #063d4d !important;
  font-size: 12px !important;
  background: transparent;
  /* padding: 0.25rem 1rem; */
  display: flex;
  font-weight: 600;
  /* border: 1px solid #ccc; */
  padding: 0.25rem 0;
  border-radius: 8px;
  /* background: #fff; */
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  max-width: fit-content;
  margin-right: 10px;
  width: 100% !important;
}
.load-more-btn {
  font-size: 16px;
  padding: 16px 30px;
  border-radius: 40px;
  border: none;
}

.screener-list .mobile-data {
  display: flex;
  width: 50%;
}
.screener-list .sceener-data {
  width: 100%;
  display: flex;
  align-items: center;
}
.screener-list .rate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  text-align: center;
}
.screener-list .rate span {
  display: inline-block;
  margin-top: 4px;
  color: #063d4d;
  font-size: 12px;
  font-weight: 600;
}

.screener-list .rate img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin: 0 auto;
}
.mutualfund-detail.screener-list .mobile-data {
  width: 80px;
  margin-top: 16px;
}
.mutualfund-detail.screener-list.screener-list .rate {
  width: 100%;
  flex-direction: row;
}
.mutualfund-detail.screener-list,
.mutualfund-detail.screener-list:hover {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}
.mutualfund-detail.screener-list .list-icon {
  border: 1px #e6e6e6 solid;
  border-radius: 10px;
  margin: 0 30px 0 0;
  padding: 15px;
  display: flex;
  width: 116px;
  justify-content: center;
  height: 100%;
}
.mutualfund-detail.screener-list .list-icon img {
  height: 75px;
  width: 75px;
  object-fit: contain;
}
.mutualfund-detail.screener-list .rate img {
  margin: 0 0 0 10px;
}
.mutualfund-detail.screener-list .rate span,
.mutualfund-detail.screener-list .screener-info p {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  line-height: 34px;
}
.portfolioCardLabel {
  font-size: 12px;
  font-weight: 600;
}
.sip-deails {
  background: #f7f7f7;
  border-radius: 15px;
  padding: 55px 40px;
  margin-bottom: 80px;
  /* margin-top: 100px !important; */
}

@media all and (min-width: 768px) {
  .sip-deails {
    margin-top: 120px !important;
  }
}
.sip-deails .data {
  font-size: 16px;
  color: #949494;
}
.sip-deails .data .value {
  color: #000;
}

.messenger {
  position: absolute;
  right: 50px;
  bottom: 30px;
}
.messenger img {
  height: 94px;
  width: 94px;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .sidebar .user-block .user-img {
    margin-right: 16px;
  }
}
@media (max-width: 992px) {
  .analytics-area .analytics-search-area {
    flex-wrap: wrap;
  }
  .analytics-search {
    width: 44%;
    margin-bottom: 15px;
  }
  .analytics-area {
    margin-bottom: 40px;
  }
  .screener-list {
    flex-direction: column;
  }
  .screener-list .sceener-data {
    width: 100%;
  }
  .screener-list .mobile-data {
    width: 100%;
    margin-top: 16px;
  }
  .registration-data {
    margin-right: 0;
  }
  .navbar form.d-flex {
    flex-direction: column;
  }
  .navbar form {
    margin-bottom: 16px;
  }
  .navbar-nav {
    margin-top: 16px;
    border-top: 1px #f0f0f0 solid;
    padding-top: 16px;
  }
  .navbar .nav-link {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .search-area {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .form-data .btn-green {
    padding: 16px 18px;
  }
}
@media (max-width: 767px) {
  .form-data .btn-green {
    padding: 10px 15px;
  }
  .messenger {
    right: 16px;
    bottom: 16px;
  }
  .form-data .btn-green {
    padding: 10px 15px;
    font-size: 16px;
  }

  .registration-data h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }
  .registration-data span {
    margin-bottom: 16px;
  }
  .messenger img {
    height: 54px;
    width: 54px;
  }
}
@media (max-width: 575px) {
  address .icon {
    margin-left: 0;
  }
  .registration-data h1 {
    font-size: 28px;
  }
  .registration-data span,
  .registration-data small {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 460px) {
  .analytics-search {
    width: 83%;
    margin-bottom: 15px;
  }

  .screener-list {
    padding: 15px;
  }
  .screener-list .screener-info p {
    font-size: 16px;
  }
  .screener-list .screener-info > .d-flex {
    flex-wrap: wrap;
  }
  .screener-list .screener-info .budget {
    margin-bottom: 10px;
    margin-right: 0;
  }
}
.get_shadow {
  -webkit-box-shadow: -1px 0px 23px -7px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -1px 0px 23px -7px rgba(0, 0, 0, 0.25);
  box-shadow: -1px 0px 23px -7px rgba(0, 0, 0, 0.25);
}

.cardx_position_image {
  width: 100px;
}
/* wizard */
@import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
.wizard-card-footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.base-button {
  background-color: #063d4d;
  border: none;
  color: white;
  padding: 1rem 2rem;
  margin: 2rem auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  width: 50%;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}
.base-button:hover {
  background-color: #063d4d;
}

.base-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.base-button:focus {
  outline: none;
}

.base-button:active {
  transform: translateY(2px);
}

.finish-button {
  background-color: #0a3622;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.finish-button:focus {
  outline: none;
}

.finish-button:active {
  transform: translateY(2px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

button:focus {
  box-shadow: none !important;
}

.tooltip-container {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0.9;
  z-index: 999;
  display: block;
}

.tooltip-container:hover .tooltip {
  display: block;
}

/* profile */
.user-img:hover .img-property {
  cursor: pointer;
  border: 3px solid #416052;
}

.edit-icon {
  opacity: 0 !important;
  display: absolute !important;
  font-size: 1rem !important;
  color: #416052 !important;
  /* transform: translate(450%, -230%) !important; */
  cursor: pointer !important;
}
.user-img:hover .edit-icon {
  cursor: pointer;
  opacity: 1 !important;
}

/* Login/Register Button */
.navbar .btn.btn-successs {
  display: block;
  height: auto;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #fff;
  border: none;
}

.navbar .btn.btn-successs:hover {
  background-color: hsl(138, 96%, 22%) !important;
  height: 100% !important;
  transition: background-color 0.3s ease, height 0.3s ease !important;
}

/* css for forgot password  */
.form-check {
  display: flex !important;
  /* justify-content: center; */
  align-items: center;
  gap: 0.5rem;
}

/* faq accrodion */

.indicator {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  margin-left: 10px;
  color: #000;
}

.accordion,
.card {
  /* margin: 1rem 0 !important; */
  border: none !important;
}
.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  line-height: 130%;
  background: #ebf3f0 !important;
  border: none !important;
  padding: 2rem 1rem !important;
  font-size: 1.5rem !important;
  color: #063d4d !important;
  border-radius: 8px !important;
  border-bottom: 2px solid #dfe6da !important ;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

@media screen and (max-width: 768px) {
  .accordion-button {
    font-size: 1rem !important;
  }
}
.indicator.active {
  padding: 0.5rem;
  border: solid #063d4d 2px;
  border: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator.inactive {
  padding: 0.5rem;
  border: solid #dfe6da 2px;
  border: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-header span {
  font-size: 1rem !important;
}

.accordion-button::after {
  display: none !important;
}

/*  */

.newsContent {
  margin: 1rem 0;
}

.no-scroll {
  overflow: hidden;
}

.lh-130 {
  line-height: 130%;
  max-width: 75%;
  font-weight: 800;
  margin: 0 auto;
  font-size: 22px;
  color: #063d4d;
}

.newLabelTag {
  color: #788dca;
  background: #e8ecf7;
  border-radius: 8px;
  font-size: 12px;
  padding: 0.15rem 0.5rem;
}

.highlightedText {
  text-decoration: underline;
}

.blogsSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.subTitleText {
  text-align: center;
  margin: 0 auto;
  max-width: 60vw;
  font-size: 20px;
  font-weight: 800;
  color: #063d4d;
}
@media screen and (max-width: 768px) {
  .subTitleText {
    text-align: left;
    margin: 0;
    padding: 1rem;
    max-width: 100vw;
    font-size: 16px;
    font-weight: 800;
    color: #063d4d;
  }
}

.viewMoreButton {
  width: 20vw;
  border-radius: 8px;
  margin: 1rem auto;
  background: #375042;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  color: #fff;
}

.arrowRightWishListSvg svg {
  stroke: #063d4d;
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.arrowRightSvg svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #0071bb;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.arrowRightSvg:hover svg {
  transform: translateX(0);
}

.learnAndInvest {
  padding: 4rem 1rem;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .learnAndInvest {
    padding: 2rem 1rem;
  }
}
.knowMoreButton {
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-static {
  height: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 1080px) {
  .sidebar-static {
    margin-bottom: 1rem;
  }
}

.content-scroll {
  height: 100%;
  /* background: #fff; */
  border-radius: 8px;
}

.dashboardContainer {
  height: 100%;
  min-height: 100vh;
  padding: 1rem;
  margin-top: 6rem;
}

.sidebarLink {
  color: #063d4d;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}
.trendingContentHeading {
  font-size: 20px;
  font-weight: 800;
  color: #063d4d;
}

.PhoneInputInput {
  border: none;
  background: transparent;
  font-size: 1em;
  font-weight: 600;
}

.tertiaryHeading {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.customBgHeight {
  background: #fff !important;
  min-height: 100svh;
  padding: 2rem;
}
@media screen and (max-width: 1080px) {
  .customBgHeight {
    padding: 0rem;
  }
}

.form-check-input:checked {
  border: none !important;
}
.form-check-input {
  margin-top: -0.1rem;
  border: 2px solid #063d4d;
}

@media screen and (max-width: 1080px) {
  .nriInvestImage {
    max-width: 200px;
  }
  .blogRow {
    max-width: 95vw;
  }
}

.mainContent {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
}

.slick-slider {
  background: #fff;
  max-height: 70vh;
}

.footer-info {
  font-size: 16px;
}

.profile-process-container {
  margin: 5rem auto;
  padding: 3rem 1rem;
  border-radius: 12px;
  background: #fff;
}

.step-progress {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
.text-success {
  color: #063d4d !important;
}
.step-progress li {
  flex: 1;
  text-align: center;
  padding: 10px;
  position: relative;
  font-weight: 600;
  cursor: default;
  background: transparent;
  transition: all 0.3s, color 0.3s;
}
@media screen and (max-width: 768px) {
  .step-progress li {
    min-width: 40%;
  }
}

.step-progress li.active {
  background-color: #416052;
  color: white;
  font-weight: 800;
  border-radius: 5px;
  filter: drop-shadow(0 0 0.3rem #063d4d);
}

.step-content {
  transition: opacity 0.5s;
}

.step-content-enter {
  opacity: 0;
}

.step-content-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.step-content-exit {
  opacity: 1;
}

.step-content-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.skip-button {
  position: absolute;
  right: 3%;
  top: 12%;
  font-size: 2rem;
  color: #063d4d;
  filter: drop-shadow(0 0 0.15rem #ccc);
}

.addNominee {
  background: #063d4d;
  margin: 1rem 0;
  border: none;
  font-weight: 600;
}
.deleteButton {
  margin: 1rem auto 0;
  font-weight: 800;
  background: transparent;
  color: red;
  border: none;
}
.step-progess-row {
  width: 100%;
  overflow-x: auto;
}
.initiatedKycText {
  font-weight: 600;
  text-align: center;
}

.mutualFundsListPage {
  display: flex;
  background: #fff;
  padding: 8rem 6rem;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide overflow to manage scrolling */
}
.pagination-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.filter-sidebar {
  flex: 1;
  max-width: 300px;
  margin-right: 20px;
  overflow-y: auto;
  height: 100%; /* Full height to allow scrolling */
  position: sticky; /* Stick to the top of the viewport */
  top: 0; /* Stick to the top */
}

@media screen and (max-width: 768px) {
  .mutualFundsListPage {
    padding: 4rem 1rem;
    flex-direction: column;
    height: 100%;
  }
  .pagination {
    margin-left: 10rem;
  }
  .pagination-container {
    /* width: 80%; */
    overflow-x: auto;
    position: relative;
  }
  .filter-sidebar {
    max-width: 100%;
    margin-right: 0;
  }
}

.funds-list {
  flex: 3;
  overflow-y: auto; /* Allow the funds list to scroll */
  height: 100%; /* Full height to enable scrolling */
  padding-bottom: 60px; /* Space for pagination */
}

.filter-section {
  margin-bottom: 1rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
  transition: all ease-in 0.3s;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.filter-section h4 {
  font-size: 1rem;
  height: 20px;
  background: #fff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding: 1rem 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  background: #fff;
}

.filter-options {
  max-height: 200px;
  overflow-y: auto; /* Scroll only the options */
  display: block;
}

.filter-section input[type="checkbox"] {
  margin-right: 10px;
}

.clearAllButton {
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #063d4d;
  margin-bottom: 1rem;
  width: 100%;
  text-align: right;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination li {
  color: #063d4d;
  margin: 1rem;
  font-size: 14px;
  font-weight: 500;
}
.pagination .active {
  color: #1a3428;
  font-weight: 800;
  font-size: 1rem;
  text-decoration: underline;
}

.accordionCustomArrow {
  font-size: 12px;
  float: right;
}

.filterName {
  margin: 0.5rem 0;
  display: flex;
}

.stockName {
  color: #063d4d;
  font-weight: 600;
  font-size: 1rem;
}

.portfolio-detail {
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.portfolio-block .title {
  font-weight: 500;
  font-size: 14px;
}

.portfolio-block .value {
  font-weight: 600;
  font-size: 16px;
}
.fundsTypeAndShare {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.data {
  margin: 0.5rem;
  gap: 0.5rem;
}
.data .detailsOf {
  font-size: 1rem;
  color: #7c7e8c;
  font-weight: 600;
}

.data .value {
  font-size: 16px;
  font-weight: 600;
}

.trusteeCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e9e9eb;
  border-radius: 8px;
}

.wishListButton,
.shareButton {
  border-radius: 50%;
  background: #063d4d;
  width: 45px;
  border: none;
  height: 45px;
  margin-right: 16px;
}
.wishListButton,
.shareButton:hover {
  background: #ccc;
}
.share-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
}

.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.share-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.dashBoardExploreFunds {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin: 2rem 0;
}
.dashBoardQuickLinks {
  display: flex;
  gap: 0.5rem;
}

.myWishList {
  margin: 1rem 0;
}

.wishListContainer {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
  max-height: 40vh;
}
.wishListContainer a {
  cursor: pointer;
  font-size: 14px;
  padding: 0.75rem 0;
  color: #063d4d;
  font-weight: 500;
  /* margin: 0.35rem 0; */
}
.wishListItem:hover {
  text-decoration: underline !important;
}
.exploreFundLinkContent {
  padding: 0.75rem 1rem;
  width: max-content;
  display: flex;
  gap: 1rem;
  border: 1px solid #8dc63f;
  border-radius: 16px;
  color: #063d4d;

  /* background: #8dc63f; */
}

.exploreFundLink {
  font-size: 16px;
  margin: 0 0.5rem;
  color: #063d4d;
  font-weight: 600;
  display: contents;
  cursor: pointer;
}

.removeFilter {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.fundsDetailsRow {
  border: 1px solid #e0e0e0;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  padding: 0.5rem 0 1rem;
  border-radius: 8px;
}
.fundsDetailsRow:nth-child(2) {
  border: none;
}
.fundsDetailsCol {
  padding: 1rem 0;
}
.fundsDetailsCol .sectionTitle {
  margin: 0;
  /* text-decoration: underline; */
}
.selectedFilterIs {
  display: flex;
  position: sticky;

  gap: 0.5rem;
  flex-wrap: wrap;
}

.selected-filters {
  margin: 1rem 0;
  position: relative;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.selected-filter-item {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 12px;
  font-weight: 400;
  margin: 0.25rem 0;
  background: #fff;
}

.mfCounts {
  color: #063d4d;
  font-weight: 600;
}

.investAndCalc {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.wishlistHeader {
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px 0;
  /* border-bottom: 1px solid #dee2e6; */
}

.wishListItemHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
}

.wishListItem {
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
}
.wishListItem:last-child {
  border-bottom: none;
}

.wishListItemContent {
  display: flex;
  justify-content: space-between;
}

.wishListItemLink {
  text-decoration: none;
  color: inherit;
}
.portfolioTitle {
  font-size: 19.2px;
  font-weight: bold;
}

.flexCol {
  flex-direction: column;
}

.customHr {
  max-width: 300px;
  margin: 0.25rem 0 0 0;
  height: 2px;
  background: #063d4d;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.dashboardSecondaryHeadings {
  font-weight: 700;
  font-size: 1.5em;
}

/* success stories css */

#content {
  max-width: 600px;
  margin: 20vh auto;
}

.testimonial {
  /* margin-bottom: 20px; */
  padding: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  #content {
    max-width: 600px;
    margin: 20vh 1rem;
  }
}
.testimonial blockquote {
  margin: 10px 10px 0;
  background: #efefef;
  padding: 20px 60px;
  position: relative;
  border: none;
  border-radius: 8px;
  font-style: italic;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 80px;
  line-height: 1;
  color: #757f9a;
  font-style: normal;
}

.testimonial blockquote:before {
  top: 0;
  left: 10px;
}

.testimonial blockquote:after {
  content: "\201D";
  right: 10px;
  bottom: -0.5em;
}

.testimonial div {
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #efefef;
  margin: 0 0 0 60px;
}

.testimonial p {
  margin: 8px 0 0 20px;
  text-align: left;
  color: #000;
}

.search-container .search-input {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid #063d4d;
  outline: none;
}

.fundsListAndSearch {
  max-width: 900px;
  width: 100%;
}
.mainHeading {
  background-image: linear-gradient(
    132deg,
    rgba(0, 113, 187, 1) 14.58%,
    rgba(84, 163, 113, 1) 65.1%,
    rgba(141, 198, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 39px;
  line-height: 48px;
  font-weight: 800;
  background-size: 30% 50%;
  padding: 0;
}
